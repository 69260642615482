<template>
  <div class="place">
    <ul class="place_ul">
      <li class="place_ul_item" :class="{'selected': place==='朝阳公园'}"
          @click="selectPlace('朝阳公园')">
        <img src="../../image/place_cygy.png" alt="cygy"><span>朝阳公园</span><span>签到点：朝阳公园</span><span>地铁站D1出口，西南角</span>
      </li>

      <li class="place_ul_item" :class="{'selected': place==='玉渊潭公园'}"
          @click="selectPlace('玉渊潭公园')">
        <img src="../../image/place_yytgy.png" alt="yytgy"><span>玉渊潭公园</span><span>签到点：西门停车场</span>
      </li>

      <li class="place_ul_item" :class="{'selected': place==='万芳亭公园'}"
          @click="selectPlace('万芳亭公园')">
        <img src="../../image/place_wftgy.png" alt=""><span>万芳亭公园</span><span>签到点：南2门</span><span>(网球中心)</span>
      </li>

      <li class="place_ul_item" :class="{'selected': place==='奥林匹克森林公园南园'}"
          @click="selectPlace('奥林匹克森林公园南园')">
        <img src="../../image/place_alpkgy.png" alt="alpkgy"><span>奥林匹克森林公园南园</span><span>签到点：森林公园南门</span><span>游客咨询中心</span>
      </li>
    </ul>
    <button class="place_button" :class="{'next':place!=''}"
    @click="nextPage()">下一步</button>
  </div>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
  name: "place",
  data(){
    return{

    }
  },
  mounted() {
    this.pageTitle('请选择参加活动的公园')
  },
  computed:{
    ...mapState({
      place:state => state.register.staff.place
    }),
    // selectedPlace(){
    //   return this.$store.getters["register/getStaff"].place
    // }

  },
  methods:{
    ...mapActions({
      selectPlace:'register/selectPlace',
      pageTitle:'register/pageTitle'
    }),
    nextPage(){
      // if (this.$store.getters["register/selectedPlace"]!=''){
      if (this.place!=''){
        this.$router.replace({name:'register.staff'})
      }
    },
    isDisabled(){
      return this.place!=''
    },
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
.place
  background white
  padding 0 30px 61px
  .place_ul
    list-style-type none
    padding 0
    margin 0
    display flex
    flex-direction row
    flex-wrap wrap
    justify-content space-between
    .place_ul_item
      width 330px
      padding-bottom 30px
      img
        width 320px
        border-radius 30px
        box-sizing border-box
        background-color none
        padding 10px
      span
        display block
        text-align center
        line-height 28px
        font-size 30px
        font-family "source han sans cn"
        color #333333
        padding-top 12px
    //选中
    .selected
      img
        background-color $theme
  .place_button
    margin  30px 0 0
    buttonSet()
    width 690px
    color $gray
    background $bcGray
  .next
    buttonSet()
    width 690px
    color white


</style>
